/* Flat UI Color */
$turquoise    : #1ABC9C;
$emerald      : #2ECC71;
$peterriver   : #3498DB;
$amethyst     : #9B59B6;
$wetasphalt   : #34495E;
$greensea     : #16A085;
$nephritis    : #27AE60;
$belizehole   : #2980B9;
$wisteria     : #8E44AD;
$midnightblue : #2C3E50;
$sunflower    : #F1C40F;
$carrot       : #E67E22;
$alizarin     : #E74C3C;
$clouds       : #ECF0F1;
$concrete     : #95A5A6;
$orange       : #F39C12;
$pumpkin      : #D35400;
$pomegranate  : #C0392B;
$silver       : #BDC3C7;
$asbestos     : #7F8C8D;
$white        : #FFFFFF;
$black        : #525252;
$dark         : #252525;
$border       : #BEBEBE;
$shadow       : transparentize(darken($black, 10%), 0.5);